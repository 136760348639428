import { ThemeOptions } from "@mui/material";
import { IStaticAdlib, IStaticDashboard } from "../embed/static/types";
import { IInsightsReport, IStudyFilterBase } from "../insights/insightsTypes";
import { IChartSettings, IICPRequirement, IReportQuery } from "./report";

export enum IEmbedModeType {
  App = "app",
  ReportId = "report_id",
  DashboardId = "dashboard_id",
  ReportQueries = "report_queries",
  Insights = "insights",
  InsightsStudy = "insights_study",
  InsightsStudyCard = "insights_study_card",
  InsightsTemplatesList = "insights_templates_list",
  StaticDashboard = "static_dashboard",
  StaticAdlib = "static_ad_lib",
  OutreachAnalysis = "outreach_analysis",
}

export type IEmbedMode =
  | IAppEmbedMode
  | IDashboardEmbedMode
  | IReportEmbedMode
  | IReportQueriesEmbedMode
  | IInsightsEmbedMode
  | IStaticDashboardEmbedMode
  | IStaticAdlibEmbedMode
  | IInsightsStudyEmbedMode
  | IInsightsStudyCardEmbedMode
  | IInsightsTemplatesListEmbedMode
  | IOutreachAnalysisEmbedMode;

export interface IBaseEmbedMode {
  type: IEmbedModeType;
  themeOverrides?: ThemeOptions;
}

export interface IAppEmbedMode extends IBaseEmbedMode {
  type: IEmbedModeType.App;
}

export interface IDashboardEmbedMode extends IEmbedDisplayOptions, IBaseEmbedMode {
  type: IEmbedModeType.DashboardId;
  dashboardId: string;
}

export interface IReportEmbedMode extends IBaseEmbedMode {
  type: IEmbedModeType.ReportId;
  reportId: string;
}

export interface IReportQueriesEmbedMode extends IReportQueriesEmbedDisplayOptions, IBaseEmbedMode {
  type: IEmbedModeType.ReportQueries;
  reportQueries: IEmbeddedReportQuery[];
}

export interface IEmbeddedReportQuery extends IReportQuery {
  chartSettings?: IChartSettings;
}

export interface IEmbedDisplayOptions extends IReportQueriesEmbedDisplayOptions {
  hideHeader?: boolean;
  hideActions?: boolean;
  themeOverrides?: ThemeOptions;
}

export interface IReportQueriesEmbedDisplayOptions {
  chartsPerRow?: number;
}

export interface IInsightsEmbedMode extends IBaseEmbedMode {
  type: IEmbedModeType.Insights;
}

export interface IInsightsStudyEmbedMode extends IBaseEmbedMode {
  type: IEmbedModeType.InsightsStudy;
  templateId: string;
  talentGroup: IICPRequirement[] | string;
  joid?: string;
  comparisons?: IStudyFilterBase[];
}

export interface IInsightsStudyCardEmbedMode extends IBaseEmbedMode {
  type: IEmbedModeType.InsightsStudyCard;
  templateId: string;
  talentGroup: IICPRequirement[] | string;
  comparisons?: IStudyFilterBase[];

  placeholderReport: IInsightsReport;
  size: "small" | "medium" | "large";
}

export interface IInsightsTemplatesListEmbedMode extends IBaseEmbedMode {
  type: IEmbedModeType.InsightsTemplatesList;
  templateIds?: string[]; // If not provided, all templates will be shown
  talentGroup: IICPRequirement[] | string;
  comparisons?: IStudyFilterBase[];
  comparisonCompanyRefs?: string[];
  size: "small" | "medium" | "large";
  joid?: string;
}

export interface IStaticDashboardEmbedMode extends IBaseEmbedMode {
  type: IEmbedModeType.StaticDashboard;
  dashboard: IStaticDashboard;
}

export interface IStaticAdlibEmbedMode extends IBaseEmbedMode {
  type: IEmbedModeType.StaticAdlib;
  adlib: IStaticAdlib;
}

export interface IOutreachAnalysisEmbedMode extends IBaseEmbedMode {
  type: IEmbedModeType.OutreachAnalysis;
}
